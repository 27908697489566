<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>기프트 관리</h2>
    </div>
    <div class="box one full">
      <div class="inputBox">
        <div class="flexL">
          <p class="bold">
            카테고리
            <span>*</span>
          </p>
          <select class="searchCondition" v-model="category">
            <option value disabled>선택</option>
            <option value="consult">상담 스탬프 기프트</option>
            <option value="contract">계약 스탬프 기프트</option>
          </select>
        </div>
        <div class="flexL">
          <p class="bold">
            제목
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div>
        <div class="flexL">
          <p class="bold">
            스탬프 개수
            <span>*</span>
          </p>
          <select class="searchCondition" v-model="numOfStamp">
            <option value disabled>선택</option>
            <option :value="1">1</option>
            <option :value="2">2</option>
            <option :value="3">3</option>
            <option :value="4">4</option>
            <option :value="5">5</option>
            <option :value="6">6</option>
            <option :value="7">7</option>
            <option :value="8">8</option>
            <option :value="9">9</option>
            <option :value="10">10</option>
          </select>
        </div>
        <div class="flexB double">
          <h2>상품 등록</h2>
          <button class="line add" @click="handleItem">상품 추가</button>
        </div>

        <div class="flexL" v-for="(data, i) in items" :key="i">
          <p class="bold">
            상품
            <span>*</span>
          </p>
          <div class="flex">
            <input type="text" v-model="data.gift" />
            <button @click="deleteGift(i)" class="delete">삭제</button>
          </div>
        </div>
      </div>

      <div class="buttonWrap">
        <router-link to="/admin/gift/manage">목록</router-link>
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  createGift,
  fetchGiftDetail,
  updateGift,
  removeGift,
} from "@/api/admin";
export default {
  data() {
    return {
      editMode: false,
      moment: moment,
      title: "",
      category: "",
      thumbnail: "",
      file: "",
      images: [],
      giftId: "",
      updatedAt: "",
      createdAt: "",
      numOfStamp: 0,
      users: [],
      items: [
        {
          gift: "",
          serial: "",
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "13-2");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.giftId = this.$route.query.id;
      this.getGift();
    }
  },
  methods: {
    comma(str) {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    removeChar(event) {
      if (
        event.keyCode == 8 ||
        event.keyCode == 46 ||
        event.keyCode == 37 ||
        event.keyCode == 39
      )
        return;
      else {
        this.price = this.comma(
          this.uncomma(event.target.value.replace(/[^0-9]/g, ""))
        );

        event.preventDefault();
      }
    },
    handleItem() {
      let item = {
        gift: "",
        serial: "",
      };
      this.items.push(item);
    },
    getGift() {
      fetchGiftDetail(this.giftId).then((res) => {
        if (res.data.status == 200) {
          this.users = res.data.data.users;
          this.title = res.data.data.title;
          this.category = res.data.data.category;
          this.items = res.data.data.items;
          this.numOfStamp = res.data.data.numOfStamp;
          this.createdAt = res.data.data.createdAt;
          this.updatedAt = res.data.data.updatedAt;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
    deleteGift(i) {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        this.items = this.items.filter((data, index) => index !== i);
        let data = {
          giftId: this.giftId,
          items: this.items,
        };
        updateGift(data).then((res) => {
          if (res.data.status == 200) {
            this.getGift();
            this.$notify({
              title: "Success",
              message: "상품 정보가 삭제 되었습니다.",
              type: "success",
            });
            // this.$router.replace("/admin/gift/manage");
          }
        });
      });
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removeGift(this.giftId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "상품 정보가 삭제 되었습니다.",
              type: "success",
            });
            this.$router.replace("/admin/gift/manage");
          }
        });
      });
    },
    submit() {
      if (this.category == "") {
        this.$alert("카테고리를 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      } else if (this.items.length == 0) {
        this.$alert("기프트 항목을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      let data = {
        giftId: this.giftId,
        items: this.items,
        category: this.category,
        title: this.title,
        numOfStamp: this.numOfStamp,
      };
      if (this.editMode) {
        updateGift(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("기프트 정보가 수정 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/gift/manage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createGift(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("상품 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/gift/manage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
  },
};
</script>
